import GIF from "./Solez.gif";
import backgrounds from "./background/background";
import fills from "./Fill/fill";
import masks from "./Mask/mask";
import outlines from "./Outline/outline";
import patches from "./Patch/patch";
import WGMI from "./WGMI/wgmi";
import soles from "./sole/sole";
import stiches from "./Stitch/stiches";
import twitter from "./twitter.png";
import discord from "./discord.png";
import DE from "./digitaleyes.svg";
import SE from "./SE/SE";
import banner from "./banner.png";

const images = {
  GIF,
  backgrounds,
  fills,
  masks,
  outlines,
  patches,
  WGMI,
  soles,
  stiches,
  twitter,
  discord,
  DE,
  SE,
  banner,
};

export default images;
