import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Rarities from "./pages/Rarities";
import Navbar from "./components/Navbar";
import SeRarities from "./pages/SeRarities";
import Footer from "./components/Footer";

export default function App() {
  return (
    <div className="divider">
      <div className="spacer"> </div>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />}></Route>
        <Route path="/rarities" element={<Rarities />}></Route>
        <Route path="/se-rarities" element={<SeRarities />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}
