import lilac from "./lilac.jpg";
import khaki from "./khaki.jpg";
import orange from "./orange.jpg";
import red from "./red.jpg";
import blue from "./blue.jpg";

const patches = {
  lilac,
  khaki,
  orange,
  red,
  blue,
};

export default patches;
