import lilac from "./lilac.jpg";
import muskSticks from "./muskSticks.jpg";
import skyBlue from "./skyBlue.jpg";
import splatter from "./Splatter.jpg";
import springGreen from "./springGreen.jpg";
import sunny from "./Sunny.jpg";
import soleFade from "./soleFade.jpg";
const backgrounds = {
  lilac,
  muskSticks,
  skyBlue,
  splatter,
  springGreen,
  sunny,
  soleFade,
};

export default backgrounds;
