import bleached from "./bleached.jpg";
import denim from "./denim.jpg";
import fire from "./fire.jpg";
import greenShoes from "./greenShoes.jpg";
import soleFade from "./soleFade.jpg";
import zebra from "./zebra.jpg";
import yellow from "./yellow.jpg";

const fills = {
  bleached,
  denim,
  fire,
  greenShoes,
  soleFade,
  zebra,
  yellow,
};

export default fills;
