import blue from "./blue.jpg";
import white from "./white.jpg";
import yellow from "./yellow.jpg";
import navy from "./navy.jpg";

const stiches = {
  blue,
  white,
  yellow,
  navy,
};

export default stiches;
