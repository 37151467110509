import React from "react";
import classes from "./Footer.module.css";
import photos from "../assets/photos";

export default function Footer() {
  return (
    <footer>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <a href="https://twitter.com/SolezOnSolana" target="_blank">
            <img
              id={classes.twitterIcon}
              src={photos.twitter}
              alt="twitter"
            ></img>
          </a>
        </div>
        <div className={classes.iconContainer}>
          <a href="https://discord.gg/bercBaFYMy" target="_blank">
            <img
              id={classes.discordIcon}
              src={photos.discord}
              alt="discord"
            ></img>
          </a>
        </div>
        <div className={classes.iconContainer}>
          <a
            href="https://digitaleyes.market/collections/Solez"
            target="_blank"
          >
            <img id={classes.DEIcon} src={photos.DE} alt="DigitalEyes"></img>
          </a>
        </div>
      </div>
    </footer>
  );
}
