import React from "react";
import classes from "./Rarities.module.css";
import photos from "../assets/photos";

export default function Rarities() {
  return (
    <div className={classes.page}>
      <div className={classes.spacer}></div>
      <h1 className={classes.sectionTitle}>Backgrounds</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.backgrounds.lilac}
            alt="Lilac"
          />
          <h3 className={classes.statsText}>Lilac 23%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.backgrounds.springGreen}
            alt="Spring"
          />
          <h3 className={classes.statsText}>Spring 22%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.backgrounds.skyBlue}
            alt="Sky Blue"
          />
          <h3 className={classes.statsText}>Sky Blue 15%</h3>{" "}
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.backgrounds.sunny}
            alt="skyBlue"
          />
          <h3 className={classes.statsText}>Sunny 12%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.backgrounds.muskSticks}
            alt="muskSticks"
          />
          <h3 className={classes.statsText}>Musk Sticks 11%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.backgrounds.splatter}
            alt="splatter"
          />
          <h3 className={classes.statsText}>Splatter 11%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.backgrounds.soleFade}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Sole Fade 6%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Retro Bonus</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.masks.eighties}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>80s Grid 10%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.masks.sweep} alt="Shoe" />
          <h3 className={classes.statsText}>Sweep Da Floors 10%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Outlines</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.soles.white} alt="Shoe" />
          <h3 className={classes.statsText}>Classic Black 78%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.outlines.blue}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Deep Blue 22%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Shoe Color</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.soles.white} alt="Shoe" />
          <h3 className={classes.statsText}>Classic White 32%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.fills.zebra} alt="Shoe" />
          <h3 className={classes.statsText}>Zebra 12%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.fills.denim} alt="Shoe" />
          <h3 className={classes.statsText}>Classic Denim 11%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.fills.fire} alt="Shoe" />
          <h3 className={classes.statsText}>Fire 10%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.fills.yellow} alt="Shoe" />
          <h3 className={classes.statsText}>Loud Lemon 10%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.fills.greenShoes}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Spring Green 10%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.fills.bleached}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Bleched Denim 9%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.fills.soleFade}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Sole Fade 5%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Rubber</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.soles.white} alt="Shoe" />
          <h3 className={classes.statsText}>Classic White 71%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.soles.blue} alt="Shoe" />
          <h3 className={classes.statsText}>SE Baby Aqua 29%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Laces</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.soles.white} alt="Shoe" />
          <h3 className={classes.statsText}>White 100%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Stitching</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.outlines.black}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Black 30%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.stiches.white}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>White 19%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.stiches.yellow}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Tumeric Yellow 18%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.stiches.blue} alt="Shoe" />
          <h3 className={classes.statsText}>Baby Blue 18%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.stiches.navy} alt="Shoe" />
          <h3 className={classes.statsText}>Navy 17%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Patch</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.patches.lilac}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Lite Lilac 22%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.patches.blue} alt="Shoe" />
          <h3 className={classes.statsText}>Deep Blue 22%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.patches.red} alt="Shoe" />
          <h3 className={classes.statsText}>Poppy 19%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.patches.orange}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Burnt Sun 19%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.patches.khaki}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Lite Khaki 18%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>WGMI</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.WGMI.black} alt="Shoe" />
          <h3 className={classes.statsText}>Classic Black 48%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.WGMI.yellow} alt="Shoe" />
          <h3 className={classes.statsText}>Yellow 41%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.WGMI.fade} alt="Shoe" />
          <h3 className={classes.statsText}>Sole Fade 11%</h3>
        </div>
      </div>
    </div>
  );
}
