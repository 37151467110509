import snorkelBlue from "./snorkelBlue.jpg";
import quietMint from "./quietMint.jpg";
import tropicalPink from "./tropicalPink.jpg";
import squishWallpaper from "./squishWallpaper.jpg";
import hotPink from "./hotPink.jpg";
import lemon from "./lemon.jpg";
import watermelon from "./watermelon.jpg";
import denim from "./denim.jpg";
import seaweed from "./seaweed.jpg";
import whiteSole from "./whiteSole.jpg";
import blueSole from "./blueSole.jpg";
import fern from "./fern.jpg";
import whiteStitch from "./whiteStitch.jpg";
import khaki from "./khaki.jpg";

const SE = {
  snorkelBlue,
  quietMint,
  tropicalPink,
  squishWallpaper,
  hotPink,
  lemon,
  watermelon,
  denim,
  seaweed,
  whiteSole,
  blueSole,
  fern,
  whiteStitch,
  khaki,
};

export default SE;
