import React from "react";
import classes from "./SeRarities.module.css";
import photos from "../assets/photos";

export default function SeRarities() {
  return (
    <div className={classes.page}>
      <div className={classes.spacer}></div>
      <h1 className={classes.sectionTitle}>Backgrounds</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.SE.snorkelBlue}
            alt="Lilac"
          />
          <h3 className={classes.statsText}>Snorkel Blue 37%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.SE.quietMint}
            alt="Spring"
          />
          <h3 className={classes.statsText}>Quiet Mint 36%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.SE.tropicalPink}
            alt="Sky Blue"
          />
          <h3 className={classes.statsText}>Tropical Pink 28%</h3>{" "}
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Retro Bonus</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.SE.squishWallpaper}
            alt="Shoe"
          />
          <h3 className={classes.statsText} id={classes.tooBig}>
            Squish Wallpaper 36%
          </h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.SE.hotPink} alt="Shoe" />
          <h3 className={classes.statsText} id={classes.tooBig}>
            Hot Pink Signature 36%
          </h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Outlines</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.outlines.black}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Black 100%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Shoe Color</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.SE.lemon} alt="Shoe" />
          <h3 className={classes.statsText}>Lemon Sorbet 30%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.SE.watermelon}
            alt="Shoe"
          />
          <h3 className={classes.statsText} id={classes.tooBig}>
            Watermelon Sorbet 26%
          </h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.SE.denim} alt="Shoe" />
          <h3 className={classes.statsText}>Faded Denim 26%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.SE.seaweed} alt="Shoe" />
          <h3 className={classes.statsText}>Sunny Seaweed 19%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Rubber</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.SE.whiteSole} alt="Shoe" />
          <h3 className={classes.statsText}>Classic White 54%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.SE.blueSole} alt="Shoe" />
          <h3 className={classes.statsText}>Skywalker Blue 46%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Laces</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.outlines.black}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>White 100%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Stitching</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.SE.fern} alt="Shoe" />
          <h3 className={classes.statsText}>Fern Green 64%</h3>
        </div>
        <div className={classes.photoContainer}>
          <img
            className={classes.photo}
            src={photos.SE.whiteStitch}
            alt="Shoe"
          />
          <h3 className={classes.statsText}>Classic White 36%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>Patch</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.SE.khaki} alt="Shoe" />
          <h3 className={classes.statsText}>Quiet Khaki 100%</h3>
        </div>
      </div>

      <h1 className={classes.sectionTitle}>WGMI</h1>
      <div className={classes.galleryContainer}>
        <div className={classes.photoContainer}>
          <img className={classes.photo} src={photos.WGMI.black} alt="Shoe" />
          <h3 className={classes.statsText}>Classic Black 100%</h3>
        </div>
      </div>
    </div>
  );
}
