import black from "./black.jpg";
import fade from "./fade.jpg";
import yellow from "./yellow.jpg";

const WGMI = {
  black,
  fade,
  yellow,
};

export default WGMI;
