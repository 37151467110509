import React from "react";
import { Link } from "react-router-dom";
import classes from "./Navbar.module.css";

export default function Navbar() {
  return (
    <header className={classes.navContainer}>
      <nav className={classes.navObject}>
        <div className={classes.buttonContainer}>
          <Link to="/" className={classes.link}>
            {" "}
            <h1 className={classes.linkText}>Home</h1>{" "}
          </Link>
        </div>
        <div className={classes.buttonContainer}>
          <Link to="/rarities" className={classes.link}>
            {" "}
            <h1 className={classes.linkText}>Rarities </h1>{" "}
          </Link>
        </div>
        <div className={classes.dropdownContainer}>
          <Link
            to="/se-rarities"
            className={classes.link}
            id={classes.dropdownLink}
          >
            {" "}
            <h1 className={classes.linkText} id={classes.dropdownText}>
              Special Edition Rarities{" "}
            </h1>{" "}
          </Link>
        </div>
      </nav>
    </header>
  );
}
