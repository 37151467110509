import React from "react";
import classes from "./Home.module.css";
import photos from "../assets/photos";

export default function Home() {
  return (
    <div className={classes.background}>
      <div className={classes.titling}>
        <h1 className={classes.title}> Solez</h1>
        <h2 className={classes.subtitle}>Crypto kicks for crypto pics!</h2>
        <div className={classes.slideshowContainer}>
          <a
            href="https://digitaleyes.market/collections/Solez"
            target="_blank"
          >
            <img
              src={photos.GIF}
              alt="Flashing Solez GIF"
              className={classes.slideshow}
            />
          </a>
        </div>
      </div>
      <div className={classes.paragraphContainer}>
        <h2 className={classes.paragraphHeader}>What are Solez.</h2>
        <p className={classes.text}>
          Solez launched like a rocket into the digital fashion stage in October
          2021 with a limited edition 80s pixel kicks collection. Making our
          community the OG pixel sneakerheads on the Solana Blockchain. <br />
          <br /> Solez are Inspired by the most popular fashion accessory of all
          time; the awesomely simple stylish and practical sneaker. Drawing from
          the 80s to trace trends and mash them up into the now, Solez is a
          success story with a initial 1001 stealth launch mint sellout,
          followed by a limited edition collection of 101 being airdropped out
          to community members! This never to be repeated collections speaks for
          itself.
        </p>
      </div>
      <div className={classes.paragraphContainer}>
        <h2 className={classes.paragraphHeader}>Royalties.</h2>
        <p className={classes.text}>
          There will be 5% royalties on sales this is divided into the
          following: <br /> ◎2.5% Split between devs to fund the ongoing work
          put into the collection. <br /> ◎1.25% Will be donated to Sea Shepard
          in our to give back to the earth. <br /> ◎1.25% will be reserved for
          the community treasury, we will look to hold votes on how this is
          spent in the future through a DAO. In the mean time this will be used
          to sweep the floor and give back to Hodlers! Feel free to suggest
          potential uses in our discord!
        </p>
      </div>
      <div className={classes.paragraphContainer}>
        <h2 className={classes.paragraphHeader}>About Us.</h2>
        <p className={classes.text}>
          An antipodean mother and son dev team, passionate about fashion and
          the exciting developments of crypto! We recognise the massive
          potential for utility in the NFT space and have exciting work in the
          pipeline! Feel free to message us on social media or email
          happyawesomepeople@gmail.com
        </p>
      </div>
    </div>
  );
}
